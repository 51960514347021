import { IModal } from '@/components';
import router from '@/router';
import store from '@/store';
import {
  ICheckbox,
  IDescription,
  IInput, IRadio, ISelect, ITextarea,
} from '@/components/form/index';
import { IProduct } from '@/models/product';
import { ITranslations } from '@/translations/index';

export const formInputs: Array<IInput | IRadio | ICheckbox | ITextarea | ISelect | IDescription> = [];

export interface INewProductInputs {
  name: IInput;
}

export const form: Partial<IProduct> = {
  id: 0,
};
export const inputs = () => {
  const translations = store.getters.getTranslations as ITranslations;

  const inputs: Partial<INewProductInputs> = {
    name: {
      id: 'new-product-name',
      name: 'new_product_name',
      value: '',
      label: translations.exhibitors.products.inputs.name,
      type: 'text',
    },
  };
  return inputs;
};

export const modal: IModal = {
  isLarge: false,
  isActive: true,
  isCard: true,
  close: (productId?: number) => {
    if (productId) {
      router.push(
        `/event/${store.getters.getContextEvent.id}/manage-exhibitor/${store.getters.getContextExhibitor.id}/product/${productId}`,
      );
    } else {
      router.push(
        `/event/${store.getters.getContextEvent.id}/manage-exhibitor/${store.getters.getContextExhibitor.id}/products`,
      );
    }
  },
};

export const statusSelect = () => {
  const translations: ITranslations = store.getters.getTranslations;

  const statusSelect: ICheckbox = {
    id: 'product-status',
    name: 'product_status',
    label: translations.common.published,
    message: translations.exhibitors.productInteractions.publish_warning,
    value: false,
  };

  return statusSelect;
};

export const productFilters = () => {
  const translations: ITranslations = store.getters.getTranslations;

  const statusFilterSelect: ISelect = {
    id: 'product-status',
    name: 'product_status',
    value: 'all',
    options: [{
      label: translations.common.published,
      value: 'submitted',
    }, {
      label: translations.common.not_published,
      value: 'not-submitted',
    }, {
      label: translations.common.all,
      value: 'all',
    }],
  };

  const searchInput: IInput = {
    id: 'product-status',
    name: 'product_status',
    placeholder: translations.exhibitors.products.search_placeholder,
    type: 'text',
    value: '',
  };

  return { statusFilterSelect, searchInput };
};
