// @ is an alias to /src
import { MutationPayload } from 'vuex';
import Loading from '@/components/loading.vue';
import ProductCard from '@/partials/ProductCard.vue';
import router from '@/router';
import { IProductParams, IProductsState, IStateProduct } from '@/store/modules/products';
import { IProduct, IProductItem } from '@/models/product';
import { IFormQuestion, IFormAnswer } from '@/models/form';
import Input from '@/components/form/input.vue';
import Select from '@/components/form/select.vue';
import store from '@/store';
import { productFilters } from './index';
import { getProductLimits, getProductList } from '@/api/products';
import { computed, defineComponent } from '@vue/composition-api';
import useContext from '@/composition/context';

export default defineComponent({
  components: {
    Loading, Select, Input, ProductCard,
  },
  data() {
    return {
      productFilters: productFilters(),
      filters: {
        status: '',
        search: '',
      },
      event: 0,
      exhibitor: 0,
      searchTimeout: 0,
      productsList: [] as IProductItem[],
      limit: 9999,
      page: 1,
      page_count: 0,
      item_count: 0,
      loading: false,
    }
  },
  setup() {
    const { translations } = useContext();
    const products = computed(() => store.getters.getProducts as IStateProduct[])

    return {
      translations,
      products,
    }
  },
  async created() {
    this.event = parseInt(this.$route.params.event, 10);
    this.exhibitor = parseInt(this.$route.params.exhibitor, 10);
    this.productFilters.searchInput.onInput = this.searchProducts;
    this.productFilters.statusFilterSelect.onChange = this.filterProducts;

    store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === 'setProducts' || mutation.type === 'newProduct') {
        this.loading = true;
        getProductList(productsParams).then(response => {
          this.productsList = response.data.results;
          this.page = response.data.current;
          this.item_count = response.data.item_count;
          this.page_count = response.data.page_count;
        });
      }
    });

    const productsParams: IProductParams = {
      exhibitor: this.exhibitor,
      page: 1,
      event: this.event,
      page_size: 10,
    };
    this.loading = true;

    const responseProducts = await getProductList(productsParams);
    this.productsList = responseProducts.data.results;
    this.productsList = responseProducts.data.results;
    this.page = responseProducts.data.current;
    this.item_count = responseProducts.data.item_count;
    this.page_count = responseProducts.data.page_count;
    this.loading = false;

    const response = await getProductLimits({ exhibitor: this.exhibitor });
    const exhibitor = response.data.results[0];
    if (exhibitor) {
      this.limit = exhibitor.limit;
    }
  },
  methods: {
    async nextPage() {
      this.loading = true;

      const params: IProductParams = {
        page: this.page + 1,
        exhibitor: this.exhibitor,
        event: this.event,
        page_size: 10,
      };
      const responseProducts = await getProductList(params);
      this.page = responseProducts.data.current;
      this.item_count = responseProducts.data.item_count;
      this.page_count = responseProducts.data.page_count;
      this.productsList = [...this.productsList, ...responseProducts.data.results];
      this.loading = false;

    },

    getProductAnswer(answers: IFormAnswer[], question: IFormQuestion) {
      const answer = answers.find((answer) => answer.form_question === question.id);
      return answer ? answer.value : '';
    },

    openProductDetail(product: IProduct) {
      router.push(`/event/${this.event}/manage-exhibitor/${this.exhibitor}/products/edit/${product.id}`);
    },

    searchProducts(value: string | number) {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(async () => {
        let params = {} as IProductParams;
        const event = parseInt(router.currentRoute.params.event, 10);
        let submitted;
        if (this.filters.status === 'submitted') {
          submitted = true;
        } else if (this.filters.status === 'not-submitted') {
          submitted = false;
        }
        params = {
          page: this.page,
          event,
          submitted,
          search: value as string,
          page_size: 10,
          exhibitor: this.exhibitor,
        };
        this.loading = true;
        const responseProducts = await getProductList(params);
        this.page = responseProducts.data.current;
        this.item_count = responseProducts.data.item_count;
        this.page_count = responseProducts.data.page_count;
        this.productsList = responseProducts.data.results;
        this.loading = false;
      }, 1000);
    },

    async filterProducts(value: string) {
      const event = parseInt(router.currentRoute.params.event, 10);
      let search;
      if (this.filters.search !== '') {
        search = this.filters.search;
      }
      const params: IProductParams = {
        page: this.page,
        event,
        search,
        page_size: 10,
        exhibitor: this.exhibitor,
      };
      if (value === 'submitted') {
        params.submitted = true;
      } else if (value === 'not-submitted') {
        params.submitted = false;
      }
      this.loading = true;
      const responseProducts = await getProductList(params);
      this.page = responseProducts.data.current;
      this.item_count = responseProducts.data.item_count;
      this.page_count = responseProducts.data.page_count;
      this.productsList = responseProducts.data.results;
      this.loading = false;
    }
  }
})

